<template>
  <table-view v-loading='loading' :show-page='false'>
    <template #header>
      <view-search-form
        :queryInfo.sync='tableQuery'
        :tool-list="['grade', 'college', 'major']"
        :insert-select-all="[ 'college', 'major']"
        @on-search='renderTable(1)' />
    </template>
    <!-- body -->
    <el-table :data='tableData' style='width: 100%' border stripe>
      <el-table-column prop='bachelorIntentionSchool' label='意向学校' />
      <el-table-column prop='bachelorIntentionMajor' label='意向专业' />
      <el-table-column prop='stuNum' label='意向人数' />
    </el-table>
  </table-view>
</template>

<script>
import { getHigherSchoolCount } from '@/api/count'
import tableView from '@/vue/mixins/table-view'

export default {
  mixins: [tableView],
  data () {
    return {
      addData: {
        id: '',
        title: '',
        isOpen: false,
        type: ''
      },
      tableQuery: {
        campusId: null,
        grade: new Date().getFullYear(),
        collegeId: null,
        majorId: null
      },
      stuStatus: this.$store.state.systemParam.stuStatus,
      stuInfo: []
    }
  },
  methods: {
    // 获取学生列表
    async renderTable (pageNum) {
      this.loading = true
      try {
        const res = await getHigherSchoolCount(this.tableQuery)
        this.tableData = res.data
        this.total = res.data.total
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
